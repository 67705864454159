import React, { Component } from "react";
import styled from "styled-components";
import { Container, Row, Col } from "reactstrap";

class Block5 extends Component {
    render() {
        return (
            <Box_home>
            <Container>
            {/* <H3tiel> The way of the Fox<br /> </H3tiel> */}
            <div className="gkl40">
              <img src="./ttdk.svg" alt="" />
            </div>
            {/* <div className="gkl41">
              <img src="./ril.png" alt="" />
            </div> */}

            <p></p>
            </Container>
          </Box_home>
        );
    }
}




const Box_home = styled.section`
    position: relative;
    overflow: hidden;
    z-index:1;
    padding: 55px 0;

    @media (max-width: 770px) {
      padding:25px 0;
    }

    .gkl41{
      text-align:center;
      img{
        max-width:85%;

        @media (max-width: 770px) {
          max-width:90%;
        }

      }

      @media (max-width: 770px) {
      padding:10px;
      }
    }
`;




const H3tiel = styled.h3`
  font-size: 56px;
  font-weight: 400;
  margin-bottom: 15px;
  color: #ffffff;
  text-align:center;
  font-family: "Kalam", cursive;
`;



export default Block5;
