import React, { Component } from "react";
import styled from "styled-components";
import { Container, Row, Col } from "reactstrap";
// import ProgressBar from 'react-bootstrap/ProgressBar'

class Blocktk extends Component {
    render() {
        return (
            <Box_home id="alopp">
            <Container>
                <Grhb3>
                  <div className="ifk">
                    <img src="./fhb.svg" alt="" />
                  </div>
                  <ul className="list-socials">
                      <li>
                          <a target="_blank" href="/"><img src="./gtg.svg" alt="" /></a>
                      </li>
                      <li>
                          <a target="_blank" href="https://x.com/ippeisolana"><img src="./gx.svg" alt="" /></a>
                      </li>
                      <li>
                          <a href="/"><img src="./si2.svg" alt="" /></a>
                      </li>
                  </ul>
                </Grhb3>
            </Container>

          </Box_home>
        );
    }
}




const Box_home = styled.section`
    position: relative;
    overflow: hidden;
    padding: 0 0 35px 0;
    /* min-height:90vh; */
    background:url('./bnup.png');
    background-size: contain;
    background-position: center;
    z-index:1;
    background-repeat: no-repeat;

    .t0ch{
      text-align:center;
      color: #A0AEE5;
    }
    .list-socials{
      display: flex;
      list-style: none;
      width: 100%;
      gap: 20px;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;
      padding:25px 10px;
      margin-bottom:0;
      li{
        width:25%;
        text-align:center;
        @media (max-width: 770px) {
          width: 30%;
        }
    }
  }
`;


const Grhb3 = styled.div`
  display: flex;
  gap:30px;
  margin-bottom:25px;
  @media (max-width: 770px) {
    flex-wrap: wrap;
  }

  .ct0{
    width:31%;
    text-align:center;
    background: rgb(182 186 191 / 12%);
    border-radius: 15px;
    padding: 20px;
    border: solid 1px #152152;
    @media (max-width: 770px) {
    width:100%
    }

    .i0btw{
      margin-bottom:10px;
    }

    p{
      color: #A0AEE5;
      text-align: justify;

    }
    h4{
      color: #ECF0FF;
      font-size:24px;
    }

  }
`




const H3tiel = styled.h3`
  font-size: 56px;
  font-weight: 400;
  margin-bottom: 15px;
  color: #ffffff;
  text-align:center;
  font-family: "Kalam", cursive;
`;

const BUys = styled.div`
  background: linear-gradient(178.25deg, #03CFFF 17.89%, #0052FF 98.52%);
  max-width:235px;
  width:100%;
  margin:0 auto;
  border-radius:20px;
  text-align:center;

  a{
    color:#fff;
    font-size:26px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    height: 55px;
  }
`;



export default Blocktk;
