import React, { Component } from "react";
import styled from "styled-components";
import { Container, Row, Col } from "reactstrap";
// import ProgressBar from 'react-bootstrap/ProgressBar'

class Block3 extends Component {
    render() {
        return (
            <Box_home>
            <Container>
                {/* <H3tiel>All Good Things are Wild and Free <br />  </H3tiel> */}
                <div className="ifk8">
                  <img src="./vis2.png" alt="" />
                </div>

            </Container>
          </Box_home>
        );
    }
}




const Box_home = styled.section`
    position: relative;
    overflow: hidden;
    z-index:1;
    padding: 0 0 35px 0;
    background:url('./pul.png');
    background-size: cover;
    background-position: bottom;
    background-repeat: no-repeat;

    .ifk8{
      text-align:center;
      img{
        max-width:80%;
      }
    }

`;





const H3tiel = styled.h3`
  font-size: 56px;
  font-weight: 400;
  margin-bottom: 15px;
  color: #ffffff;
  text-align:center;
  font-family: "Kalam", cursive;
`;


export default Block3;
