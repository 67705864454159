import React, { Component } from "react";
import styled from "styled-components";
import { Container } from "reactstrap";

class Block2 extends Component {
  constructor(props) {
    super(props);
    this.addressRef = React.createRef();
  }

  handleCopy = () => {
    if (this.addressRef.current) {
      const addressText = this.addressRef.current.textContent;
      navigator.clipboard.writeText(addressText)
        .then(() => {
            alert('Address has been copied!');
        })
        .catch((err) => {
            console.error('Error copying the text: ', err);
        });
    }
  };

  render() {
    return (
      <Box_home>
        <Container>
          <div className="amin0v">
            <p>CA</p>
            <address ref={this.addressRef}>0x50b2E274047f6791C3067ea0eEDAc845F9e06CC9</address>
            <button onClick={this.handleCopy}>
              <img src="./saot.svg" alt="" />
            </button>
          </div>
        </Container>
      </Box_home>
    );
  }
}

const Box_home = styled.section`
  position: relative;
  overflow: hidden;
  z-index: 1;
  padding: 65px 0 0 0;
  @media (max-width: 770px) {
    padding: 25px 0;
  }

  .amin0v{
        /* background:url('./fak.png'); */
        background:rgb(255 255 255 / 23%);
        min-height: 95px;
        background-position: center;
        background-size: 100%;
        background-repeat: no-repeat;
        display: flex;
        padding: 10px 20px;
        gap: 25px;
        max-width: 940px;
        width: 100%;
        margin: auto;
        align-items: center;
        font-size: 28px;
        color:#ffffff;
        font-weight:700;
        margin-bottom: 50px;
        border-radius: 22px;
        border: solid 2px #ffffff;

        @media (max-width: 770px) {
            font-size:20px;
            margin-bottom: 25px;
        }

        @media (max-width: 520px) {
          font-size: 9px;
          flex-wrap: nowrap;
          min-height: 50px;
          font-weight: bold;
          gap: 5px;
          padding: 5px 10px;
        }

        button{
            background:none;
        }
        address{
            margin-bottom:0;
        }
        p{
            margin-bottom:0;
        }
  }
`;

const Thumbnail = styled.div`
  max-width: 450px;
  width: 100%;
  margin-right: 45px;
  margin: 0 auto;

  img {
    width: 100%;
  }

  @media (max-width: 770px) {
    max-width: 100%;
    margin-right: 0;
  }
`;

export default Block2;
