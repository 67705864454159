import React, { Component } from "react";
import styled from "styled-components";
import { Container, Row, Col } from "reactstrap";
// import ProgressBar from 'react-bootstrap/ProgressBar'

class Block1 extends Component {



    render() {
        return (
            <Box_home>
            <Container>
              <div className="top">
                <ul className="list-head">
                  <li>
                    <a href="/"><img className="img-vis3" src="./logoh.svg" alt="" /></a>
                  </li>
                  {/* <li>
                    <a href="#thf">$MOK</a>
                  </li>
                  <li>
                    <a href="/">How To Buy</a>
                  </li>
                  <li>
                    <a href="#alopp">Tokenomics</a>
                  </li>
                  <li>
                    <a href="#dwll">Sock Monkey</a>
                  </li>
                  <li>
                    <a href="#foxukpp">Roadmap</a>
                  </li> */}
                  <li>
                    <a href="/"><img className="s67" src="./gtg.svg" alt="" /></a>
                    <a target="_blank" href="https://x.com/ippeisolana"><img className="s67" src="./gx.svg" alt="" /></a>
                  </li>
                  <li className="w180s">
                    <a className="buynows" href="">Buy Now</a>
                  </li>
                </ul>
              </div>



                <Groupdp>
                {/* <img className="sic6" src="./ton1.svg" alt="" /> */}
                  <img src="./se64.png" alt="" />
                </Groupdp>


            </Container>

          </Box_home>
        );
    }
}




const Box_home = styled.section`
    position: relative;
    overflow: hidden;
    padding: 0 0 40px 0;
    z-index:1;
    background:url('./vv3.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    @media (max-width: 770px) {
      padding:25px 0;
    }
    .buynows {
      background: rgb(255 255 255 / 23%);
      border: solid 2px #ffffff;
      color: #ffffff;
      font-weight: bold;
      display: flex;
      max-width: 180px;
      width: 100%;
      align-items: center;
      justify-content: center;
      height: 50px;
      border-radius: 22px;
    }
    .list-head{
      list-style:none;
      padding:15px;
      display: flex;
      justify-content: space-between;
      width: 100%;
      align-items: center;
      flex-wrap: wrap;

      .img-vis3{
        max-width: 360px;
        width: 100%;
      }

      .w180s{
        max-width: 180px;
        width: 100%;
      }

      .s67{
        max-width:60px;
        width:100%;
        margin-right:7px;
      }

    }


`;


const GroupAbout = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;

  @media (max-width: 770px) {
    flex-wrap: wrap;
  }
`

const BUys = styled.div`
  width: 140px;
  margin: 0 auto;
  border-radius: 20px;
  text-align: center;
  border: solid 2px #ffffff;

  a{
    color:#fff;
    font-size:22px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    height: 40px;
  }
`;


const GroupDep = styled.div`
    display: flex;
    align-items: center;
    border: solid 1px #fff;
    border-radius: 35px;
    padding:5px;
    margin-bottom: 26px;
    color: #fff;
    max-width: 660px;
    margin: auto;
    margin-bottom: 35px;
    width: 100%;


  @media (max-width: 770px) {

  }
  @media (min-width: 1200px) {
    border:none;
    box-shadow:none;
    background:unset;
    position: absolute;
    bottom: 5%;
    right: 10%;
  }
`




const Groupdp = styled.div`
  position: relative;
  padding-top:30px;
  text-align:center;
  @media (max-width: 770px) {
    width:100%;
  }
  .cat-depos{
    margin-bottom:0;
    font-weight: bold;
    color: #ffffff;
    font-size:21px!important;
  }

  .sic6{
    max-width: 920px;
    width: 100%;
  }


`



const Thumbnail = styled.div`
  max-width:450px;
  width: 100%;
  margin-right:45px;

  img{
    width:100%;
  }

  @media (max-width: 770px) {
    max-width:100%;
    margin-right:0;
  }
`

const Title_item = styled.h3`
    font-size: 24px;
    font-weight: bold;
    line-height: 1.1;
    margin-bottom: 15px;
    color: #99378e;
`;

const Line_total = styled.p`
    color: #3d3d3d;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.5;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .pra-total {
        font-weight: bold;
        font-size: 16px;
    }
`;

export default Block1;
