import React, { Component } from "react";
import styled from "styled-components";
import { Container, Row, Col } from "reactstrap";

class Block4 extends Component {
    render() {
        return (
            <Box_home id="thf">
            <Container>
            <Nav>
              <ul>
                  <li><a href="/"><img src="./lo1.svg" alt="" /></a></li>
                  <li><a href="/"><img src="./lo2.svg" alt="" /></a></li>
                  <li><a href="/"><img src="./lo3.svg" alt="" /></a></li>
                  <li><a href="/"><img src="./lo4.svg" alt="" /></a></li>
                  <li><a href="/"><img src="./lo5.svg" alt="" /></a></li>
                  <li><a href="/"><img src="./lo6.svg" alt="" /></a></li>
                  <li><a href="/"><img src="./lo7.svg" alt="" /></a></li>
                  <li><a href="/"><img src="./lo8.svg" alt="" /></a></li>
              </ul>
            </Nav>
            {/* <H3tiel>  <br />  The Fox $FOX </H3tiel> */}
            <div className="gkl40">
              <img src="./fio.png" alt="" />
            </div>
            <div className="gkl40">
              <img src="./iop.png" alt="" />
            </div>


            </Container>
          </Box_home>
        );
    }
}




const Box_home = styled.section`
    position: relative;
    overflow: hidden;
    padding: 65px 0;
    z-index:1;
    p{
      color: #A0AEE5;
    }

    @media (max-width: 770px) {
      padding:15px 0;
    }
    .gkl40{
      text-align:center;
      img{
        max-width:95%;
      }
    }
`;



const H3tiel = styled.h3`
  font-size: 56px;
  font-weight: 400;
  margin-bottom: 15px;
  color: #ffffff;
  text-align:center;
  font-family: "Kalam", cursive;
`;



const Nav = styled.div`
  float: right;
  position: relative;
  margin-top: 25px;

  ul{
    list-style: none;
    display: flex;
    align-items: center;
    gap: 10px;

    li{
      max-width:100px;
      width:100%;

      a{
        :hover{
          opacity:.5;
        }
      }
    }
  }

  @media (max-width: 770px) {

  }
`;



export default Block4;
